<template>
  <div>
    <el-table :data="commentator" border>
      <el-table-column prop="id" label="序号" width="180"> </el-table-column>
      <el-table-column label="职位" width="180"> 
        <template slot-scope="scope">
            <span v-if="!scope.row.isadmin">ASG常驻解说<i style="color:gold" class="el-icon-s-check"></i></span>
            <span v-else>解说管理员</span>
        </template>
      </el-table-column>
      <el-table-column prop="chinaname" label="姓名" width="180"> </el-table-column>
      <el-table-column prop="email" label="邮箱" width="250"> </el-table-column>
      <el-table-column  label="操作" width="auto">
        <el-button type="success">升级</el-button>
        <el-button type="warning">替换</el-button>
        <el-button type="danger">劝退</el-button>
 
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getUserRoles } from "@/api/schedule/index";
export default {
  mounted() {
    this.initGetCommentary();
  },
  data() {
    return {
      commentator: [],
    };
  },
  methods: {
    initGetCommentary() {
      let params = {
        opname: "Commentator",
      };
      getUserRoles(params)
        .then((res) => {
          this.commentator = res.data;
          console.log(res.data)
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="less"></style>
